.all-albums {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all-albums-title {
    font-family: 'RiffFont', sans-serif;
    font-size: 36px;
    margin-bottom: 20px;
}

.all-albums-album-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    width: 95%;
    max-width: 700px;
}

.all-albums-album-item {
    position: relative;
    cursor: pointer;
}

.all-albums-album-image-container {
    position: relative; /* Ensure the container is the reference for absolute positioning */
    display: inline-block;
  }

.all-albums-album-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.all-albums-album-image-blurred {
    filter: blur(2px);
    opacity: 0.7;
    transition: filter 0.3s, opacity 0.3s;
}

.all-albums-album-image-blurred:hover {
    filter: blur(1px);
    opacity: 0.7;
  }
  

.all-albums-lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #ffffff8f; /* You can adjust the color as needed */
    pointer-events: none;
}

.all-albums-album-details {
    text-align: center;
    margin-top: 10px;
}

.all-albums-album-name {
    font-weight: bold;
}

.all-albums-album-artist {
    color: #76ABAE;
}