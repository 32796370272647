.player-info {
    flex: 1;
    padding: 0 20px;
}

.player-center-control {
    flex: 2;
    text-align: center;
}

.player-right-control {
    flex: 1;
    padding: 0 20px;
}

.artist-name {
    margin: 0;
    text-align: left;
    font-weight: 400;
    font-size: small;
    color: rgb(165, 165, 165);
}

.track-name {
    margin: 0;
    text-align: left;
    font-weight: 500;
    font-size: large;

}

.progress-bar-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.progress-bar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    margin: 0 5px;
    border-radius: 5px;
    outline: none;
    background: linear-gradient(to right, #76ABAE 0%, #76ABAE var(--progress, 0%) , #444 var(--progress, 0%) , #444 100%);
}

.progress-bar::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

.progress-bar::-moz-range-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

.progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #76ABAE;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.progress-bar::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #76ABAE;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.player-skip-button {
    color: white !important;
    font-size: 25px !important;
}

.player-play-button {
    font-size: 30px !important;
    color: white !important;
}

.shuffle-button .mute-button {
    font-size: 18px !important;
}

.player-whole {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #222831; /* Adjust this to match your design */
    color: white;
    z-index: 999; /* Ensures the player is on top of other content */
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.button-group {
    margin-bottom: 10px;
}

.audio-minute {
    font-size: small;
    color: rgb(165, 165, 165);
    margin: 0 5px;
}