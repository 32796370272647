.home-video-container {
    position: relative;
    height: 90vh; /* Full height of viewport */
    width: 100vw;  /* Full width of viewport */
    overflow: hidden;
}

/* Video should cover the entire background */
.home-video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1; /* Behind the text */
    object-fit: cover; /* Make sure video scales properly */
}

/* Text overlay */
.home-video-container .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
    width: 80%;  
    max-width: 1000px; 
    padding: 20px;
}

/* Optional: Styling for text */
.home-video-container .overlay-text h1 {
    font-size: 2rem;
    margin: 0;
}

.home-video-container .overlay-text p {
    font-size: 1.2rem;
    margin-top: 10px;
}

.home-navigate-button {
    margin-top: 50px;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #31363f;
    color: white;
    text-align: center;
    cursor: pointer;
}

.home-navigate-button:hover {
    opacity: 0.8; 
}

@media (max-width: 768px) {
    .home-video-container .overlay-text h1 {
        font-size: 1.4rem;
    }

    .home-video-container .overlay-text p {
        font-size: 1rem;
    }

    .home-navigate-button {
        font-size: 1rem;
    }
}