.hamburger-menu-container {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    background-color: transparent; /* Ensure the background is transparent */
    border: none; /* Remove any borders */
    z-index: 999;
    box-sizing: border-box;
}

.header-riff-logo {
    height: 90px;
    cursor: pointer;
    margin-left: 10px;
}

.hamburger-icon {
    color: #222831; /* Ensure the color matches your theme */
    font-size: 28px; /* Adjust the size as needed */
    cursor: pointer;
}

.hamburger-icon:hover {
    opacity: 0.8; 
}

.header-riff-logo:hover {
    opacity: 0.8; 
}