.about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-page h1 {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  
  .about-page p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  
  .about-page-fade-in {
    opacity: 0;
    animation: fadeInAnimation ease-in 1s;
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }