* {
  font-family: "Roboto Mono", monospace;
}

.album-page {
  color: #060606;
  background-color: white;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 15px;
}

.album-contents {
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
}

.album-page-album-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  animation: glow 5s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: -3px 0px 20px -1px #76ABAE;
  }
  to {
    box-shadow: 3px 3px 20px 1px #76ABAE;
  }
}

.tab-content {
  width: 98%;
  max-width: 700px;
  margin-top: 10px;
}

.album-page-artist-name {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 400;
  font-weight: 700;
}

.album-page-album-name {
  margin-block-start: 0 ;
  margin-block-end: 0 ;
  margin-top: 3px;
  font-weight: 700;
  font-size: 1.8em;
}

.album-page-album-description-item {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 2px 0;
  font-size: 0.8em;
  font-weight: 300;
}

.album-page-album-image-container {
  position: relative;
  display: inline-block;
}

.album-page-album-image-blurred {
  filter: blur(4px);
  opacity: 0.7;
  transition: filter 0.3s, opacity 0.3s;
}

.album-page-lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #ffffff8f; /* You can adjust the color as needed */
}

.album-page-album-image-blurred:hover {
  filter: blur(2px);
  opacity: 0.8;
}
