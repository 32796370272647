* {
    margin-block-start: 0;
    margin-block-end: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 400px;
    color: #060606;
}

.modal-support-text {
    font-weight: 700;
}

.modal-album-image {
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    border-radius: 10px;
    filter: blur(2px);
    opacity: 0.7;
}

.modal-header h2 {
    color: #76ABAE; /* gold color */
    font-size: 1.6em;
    font-weight: 900;
    margin-block-start: 0;
    margin-block-end: 0;
}

.modal-description {
    margin-top: 5px;
}

.modal-button {
    margin: 20px 10px 10px 10px;
    padding: 10px 16px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.modal-support-button {
    background-color: #76ABAE;
}

.modal-info-link {
    display: block;
    margin-top: 2px;
    color: #76ABAE;
    text-decoration: underline;
    font-size: 0.8em;
}