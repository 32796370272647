.tab-navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.tab-button {
    padding: 5px 10px;
    margin: 0 6px;
    border: 1px solid #d4d4d4bd;
    cursor: pointer;
    color: #585858bd;
    border-radius: 10px;
    background-color: white;
}

.tab-button.active {
    color: #060606; 
    border: 1px solid #76ABAE;
}

.tab-button:hover {
    background-color: #f6f6f6;
}