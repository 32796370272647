.track-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #cccccc4f;
    padding: 15px;
    transition: background-color 0.3s ease;
    text-align: left;
  }
  
  .track-item:hover {
    background-color: #98989853;
  }