.player-narrow-info {
    padding: 0 5px;
}

.player-narrow-artist-name {
    margin: 0;
    text-align: left;
    font-weight: 400;
    font-size: small;
    color: rgb(165, 165, 165);
}

.player-narrow-track-name {
    margin: 0;
    text-align: left;
    font-weight: 500;
    font-size: large;
}

.player-narrow-progress-bar-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.progress-bar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    margin: 0 5px;
    border-radius: 5px;
    outline: none;
    background: linear-gradient(to right, #B5CFB7 0%, #B5CFB7 var(--progress, 0%) , #444 var(--progress, 0%) , #444 100%);
}

.progress-bar::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

.progress-bar::-moz-range-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

.progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.progress-bar::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.player-narrow-play-button {
    font-size: 30px !important;
    margin: 0;
    background-color: transparent;
    color: white;
    border: 0
}

.player-narrow-whole {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player-narrow {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #101010; /* Adjust this to match your design */
    color: #fff;
    z-index: 999; /* Ensures the player is on top of other content */
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
}

.audio-minute {
    font-size: small;
    color: rgb(165, 165, 165);
    margin: 0 5px;
}