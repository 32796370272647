.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.97); 
    color: #060606;
    transform: translateX(-100%); /* Start off-screen */
    transition: transform 0.3s ease;
    z-index: 1000; /* Ensure it's above other content */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    
}

.side-menu.open {
    transform: translateX(0); /* Slide in */
}

.side-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 999; /* Just below the menu */
    display: none; /* Hidden by default */
}

.side-menu.open ~ .side-menu-overlay {
    display: block; /* Show when the menu is open */
}

.side-menu-content {
    padding: 20px;
}

.side-menu-logo {
    height: 88px;
    margin-bottom: 10px;
}

.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-list li {
    margin: 15px 0;
    cursor: pointer;
}

.bottom-section {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 200px;
}

.profile {
    display: flex;
    align-items: center;
}

.profile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-picture {
    margin-right: 5px;
}

.side-menu-login-logout {
    border: 0;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #76abaeab;
    cursor: pointer;
}