/* Main container for the sign-in page */
.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: white;
    opacity: 0; /* Start with the container hidden */
    animation: fadeIn 0.5s forwards; /* Fade in animation */
}

/* The sign-in box */
.signin-box {
    background-color: #EEEEEE;
    padding: 60px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 80%;
    max-width: 400px;
    color: #31363F;
    transform: scale(0.8); /* Start smaller */
    animation: popIn 0.5s forwards; /* Pop-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Pop-in animation for the sign-in box */
@keyframes popIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Apple logo */
.signin-riff-logo {
    width: 80px;
    margin-bottom: 10px;
}

/* Heading */
h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
}

/* Form and input field styling */
.signin-form {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.email-input {
    width: 100%;
    padding: 15px;
    padding-right: 60px;  /* Reserve space for the button */
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    box-sizing: border-box;
}

/* Submit button styling */
.submit-button {
    background-color: #76ABAE;
    color: #EEEEEE;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

/* Message after submission */
.message {
    color: #76ABAE;
    margin-top: 20px;
    font-size: 13px;
}
